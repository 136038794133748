import { graphql } from 'gatsby'

export const Fragments = graphql`
  fragment TmHomeFragment on ContentfulTmHome {
    blocks {
      name
      blockType
      contentful_id
    }
  }

  fragment ThemeFragment on ContentfulTheme {
    contentful_id
    bodyBackground
    bodyBackgroundAlt
    bodyText
    brandDefault
    brandTextDefault
    productViewWrapperBackground
    productViewContainerBackground
    iconsColor
    iconsHoverColor
    layoutBackground
    buttonBackgroundPrimaryColor
    buttonPrimaryColor
    bodyBackgroundImage {
      file {
        url
      }
    }
  }
`

export const MlBannerFluidFragment = `
  fragment MlBannerFluidFragment on MlBannerFluid {
    name
    backgroundColor
    link 
    imageMobile {
      url
    }
    imageTablet {
      url
    }
    imageDesktop {
      url
    }
    videoMobile {
      url
    }
    videoDesktopTablet {
      url
    }
  }
`

export const MlTextWithIconFragment = `
  fragment MlTextWithIconFragment on MlTextWithIcon {
    title
    text {
      json
    }
    textColor
    icon {
      url
    }
  }
`

export const OrListFragment = `
  fragment OrListFragment on OrList {
    name
    title
    titleType
    titleColor
    titleSize
    titleSizeDesktop
    centerTitle
    allowTitleLowerCase
    titleBorderColor
    titleBorderColorShadowNeon
    blockType
    brandShadow
    actionSlug
    paddingTop
    paddingBottom
    background {
      url
    }
    backgroundColor
  }
`

export const MlHeroFragment = `
  fragment MlHeroFragment on MlHero {
    sys {
      id
    }
    text
    heroType
    textColor
    buttonText
    buttonSlug
    backgroundColor
    image {
      url
    }
    callToActionImageMobile {
      url
    }
    callToActionImageTablet {
      url
    }
    callToActionImageDesktop {
      url
    }
  }
`

export const MlVideoFragment = `
  fragment MlVideoFragment on MlVideo {
    sys {
      id
    }
    title
    videoDescription
    video {
      url
    }
    videoPoster {
      url
    }
  }
`

export const ProductFragment = `
  fragment ProductFragment on Product {
    sku
    categoryName
  }
`

export const BundleFragment = `
  fragment BundleFragment on Bundle {
    sku
    categoryName
  }
`

export const OrProductSlideFragment = ` 
  fragment OrProductSlideFragment on OrProductSlide {
    sys {
      id
    }
    backgroundColor
    title
    subtitle
    showPrice
    description {
      json
    }
    image {
      url
      sys {
        id
      }
    }
    showAddButton
    showDetailsButton
    customButtonText
    bodyColor
    priceColor
    originalPriceColor
    addSpace 
    product {
      ...ProductFragment
      ...BundleFragment
    }
  }
  ${ProductFragment}
  ${BundleFragment}
`

export const MlPromoSlideFragment = `
  fragment MlPromoSlideFragment on MlPromoSlide {
    sys {
      id
    }
    actionSlug
    imageMobile {
      url
    }
    imageTablet {
      url
    }
    imageDesktop {
      url
    }
    actionSlugLong 
  }
`

export const OrFilteredListFragment = `
  fragment OrFilteredListFragment on OrFilteredList {
    attributeToFilter
    filtersCollection(limit: 10) {
      items {
        label
        filter
      }
    }
    itemsCollection {
      items {
        ...BundleFragment
        ...ProductFragment
      }
    }
    optionalFilter
  }
  ${BundleFragment}
  ${ProductFragment}
`

export const OrRecipeFragment = `
  fragment OrRecipeFragment on OrRecipe {
    sys {
      id
    }
    slug
    sliderTitle
    sliderDescription
    sliderImage {
      url
    }
    backgroundColorCard
    textColorCard
    primaryTextButton
  }
`

export const OrRecipeSlideFragment = `
  fragment OrRecipeSlideFragment on OrRecipeSlide {
    sys {
      id
    }
    name
    title
    imageMobile {
      url
    }
    imageTablet {
      url
    }
    imageDesktop {
      url
    }
    backgroundColor
    description
    primaryTextButton
    primarySlugButton
    secondaryTextButton
    secondarySlugButton
  }
`

export const OrBrandsListFragment = `
  fragment OrBrandsListFragment on OrBrandsList {
    sys {
      id
    }
    brandsCollection {
      items {
        url
      }
    }
    background {
      url
    }
  }
`

export const OrNewsFragment = `
  fragment OrNewsFragment on OrNews {
    sys {
      id
    }
    slug
    sliderTitle
    sliderDescription
    sliderImage {
      url
    }
    sliderImageBottom {
      url
    }
    sliderRichTextDescription {
      json
    }
    sliderDescriptionColorBold
    sliderButtonColor
  }
`

export const HowItWorksFragment = `
  fragment HowItWorksFragment on HowItWorks {
    sys {
      id
    }
    name
    backgroundColor
    stepsCollection(limit: 10) {
      items {
        sys {
          id
        }
        title
        text {
          json
        }
        icon {
          url
        }
      }
    }
    imageMobile {
      url
    }
    imageTablet {
      url
    }
    imageDesktop {
      url
    }
  } 
`

export const ContentFragment = `
  fragment ContentFragment on Content {
    sys {
      id
    }
    content {
      json 
    }
  }
`

export const MlCardPromoFragment = `
  fragment MlCardPromoFragment on MlCardPromo {
    promoTitle
    promoSubtitle
    promoDescription
    actionSlugLong
    products
    titleType
    googleTagManagerEventType
    googleTagManagerEventValue
    image {
      url
    } 
    sys {
      id
    }
  }
`

export const BrandFragment = `
  fragment BrandFragment on Brand {
    id
    image {
      url
    } 
    category {
      name
      mainCategoryNames
    }
  }
`

export const MlMomentCardFragment = `
  fragment MlMomentCardFragment on MlMomentCard { 
    name
    title
    subtitle
    slug
    image {
      url
      sys{
        id 
      }
    }
    sys{
      id
    }
  }
`
